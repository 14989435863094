<template>
  <div>

    <b-modal id="modal-1" title="Basic Modal" ok-disabled size="lg" hide-footer>
      <b-row>
        <b-col md="12">
          <h6><small>Nama SKPD</small>
            <br><strong>{{ detail_paket.detail_kontrak.kode_dinans }}
              {{ detail_paket.detail_kontrak.nama_dinas }}</strong>
            <br><small>Sub Kegiatan</small>
            <br><strong>{{ detail_paket.detail_kontrak.kode_sub_kegiatan }} {{
              detail_paket.detail_kontrak.nama_sub_kegiatan }}</strong>
          </h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <h6><small>Lokasi </small>
            <br><small><strong>{{ detail_paket.detail_kontrak.nm_wil }} </strong></small>
            <br><small>Jenis Kegiatan </small>
            <br><small><strong>{{ detail_paket.detail_kontrak.jenis_kegiatan }} </strong></small>
          </h6>
          <h6><small>Pagu Anggaran </small>
            <br> <strong style="font-size: 20px;"><sup>Rp. </sup>{{ Rp(detail_paket.detail_kontrak.nilai_kontrak) }}
            </strong>
          </h6>
        </b-col>
        <b-col md="5">
          <h6>
            <small>Rekanan </small>
            <br> <small><strong>{{ detail_paket.detail_kontrak.nama_rekanan }} </strong></small><br>
            <small>No /Tanggal Kontrak </small>
            <br> <small><strong>{{ detail_paket.detail_kontrak.no_kontrak }} / {{ detail_paket.detail_kontrak.tgl_kontrak
            }} </strong></small>
            <br><small>Mulai Kontrak</small>
            <br> <small><strong>{{ detail_paket.detail_kontrak.tgl_mulai }} - {{ detail_paket.detail_kontrak.tgl_selesai
            }} </strong></small>
          </h6>
        </b-col>
        <b-col md="3">
          <h6><small>Nilai Kontrak </small>
            <br> <strong style="font-size: 20px;"><sup>Rp. </sup>{{ Rp(detail_paket.detail_kontrak.nilai_kontrak) }}
            </strong>
          </h6>
          <h6><small>Realisasi </small>
            <br> <strong style="font-size: 20px;"><sup>Rp. </sup>{{ Rp(detail_paket.pencairan_total) }}
            </strong>
          </h6>
        </b-col>
      </b-row>
      <hr>
      <h5><feather-icon icon="GridIcon" size='15' /> Rincian Realisasi Kontrak</h5>

      <div v-for="realisasi in detail_paket.realisasi" v-bind:key="realisasi">
        <b-alert variant="primary" show>
          <div class="alert-body">
            <b-row>
              <b-col md="4">
                <h6><small>Title</small><br><small><strong>{{ realisasi.judul }}</strong><br>{{ realisasi.tgl_pencairan
                }}</small></h6>
              </b-col>
              <b-col md="3">
                <h6><small>Tanggal Realisasi</small><br><small><strong>{{ realisasi.tgl_pencairan }}</strong> </small>
                </h6>
              </b-col>
              <b-col md="3">
                <h6><small>Realisasi </small><br> <strong><sup>Rp. </sup>{{ Rp(realisasi.nominal) }}</strong> </h6>
              </b-col>
              <b-col md="2">
                <h6 style="text-align: center;"><small>Jenis </small><br> <strong> {{ realisasi.jenis_pencairan
                }}</strong>
                </h6>
              </b-col>
            </b-row>
          </div>
        </b-alert>
      </div>

    </b-modal>



  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BRow, BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    }
  },
  props: {
    detail_paket: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>