<template>
  <div>
    <b-card class=" ">
      <b-row style="margin-top :-10px; margin-bottom :-2px">
        <b-col md="5">
          <b-form-group>
            <label>Nama SKPD</label>
            <v-select v-model="id_dinas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_dinas"
              class=bg-light @input="loadDataAll()" :options="opd" aria-placeholder="Nama SKPD" />
          </b-form-group>
        </b-col>
        <b-col md="7">
          <b-row class="mt-1 ml-1">
            <b-col md="5">
              <b-row>
                <b-col md="12" style="margin-left: -20px;;">
                  <h6><small>Nilai Paket Kontrak</small><br>
                    <strong style="font-size: 25px;"><sup>Rp.</sup> {{ Rp(statistik.nilai_kontrak) }}</strong>
                  </h6>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row>
                <b-col md="12">
                  <h6><small>Realisasi</small><br>
                    <strong style="font-size: 25px;"> <sup>Rp.</sup>{{ Rp(statistik.realisasi) }}</strong>
                  </h6>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="2">
              <b-row style="text-align: center;">
                <b-col md="12">
                  <h6><small>Realisasi</small><br>
                    <strong style="font-size: 25px;"> {{ Rp(statistik.prosentase) }} %</strong>
                  </h6>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
        </b-col>

      </b-row>
    </b-card>
    <b-card>
      <tabel-paket :items=rs_data @LoadDetail=LoadDetail></tabel-paket>
      <detail :detail_paket="detail_paket"></detail>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BRow, BCol, BCard, BTab, BAlert, BAvatar
} from 'bootstrap-vue'
import TabelPaket from './component/TabelPaket.vue'
import Detail from './component/Detail.vue'
export default {
  components: {
    BTabs, vSelect, TabelPaket,
    BTab, BRow, BCol, BCard, BAlert, BAvatar,
    Detail,
  },
  props: {

  },
  data() {
    return {
      rs_data: [],
      statistik: [],
      opd: [],
      id_dinas: '',
      st_realisasi: '',
      detail_paket: {}
    }
  },
  mixins: [Base],
  mounted() {
    this.loadData();
    this.load_statistik();
    this.daftar_opd();
  },
  methods: {

    async LoadDetail(id_paket) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/paket/detail_paket_kontrak',
        data: {
          id: id_paket
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.detail_paket = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    loadDataAll() {
      this.st_realisasi = '';
      this.loadData();
      this.load_statistik();
    },
    LoadStatistik(val) {
      this.st_realisasi = val;
      this.loadData();
      this.load_statistik();
    },
    async loadData() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/paket/realisasi_kontrak',
        data: {
          id_master_kode_dinas: self.id_dinas.id_master_kode_dinas,
          st_realisasi: self.st_realisasi
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_statistik() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/paket/rekap_realisasi',
        data: {
          id_master_kode_dinas: self.id_dinas.id_master_kode_dinas,
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.statistik = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async daftar_opd() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/laporan/paket/daftar_opd',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.opd = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>